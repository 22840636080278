// extracted by mini-css-extract-plugin
export var root = "PlasmicPricingSection3-module--root--1Ox-j";
export var freeBox = "PlasmicPricingSection3-module--freeBox--3Dwc1";
export var foreground = "PlasmicPricingSection3-module--foreground--5ZJxe";
export var freeBox__ekSvP = "PlasmicPricingSection3-module--freeBox__ekSvP--XXqHN";
export var freeBox___7Nn6W = "PlasmicPricingSection3-module--freeBox___7Nn6W--3n1Il";
export var freeBox__urLl = "PlasmicPricingSection3-module--freeBox__urLl--2lu8k";
export var freeBox__sTtjh = "PlasmicPricingSection3-module--freeBox__sTtjh--VQZ6m";
export var freeBox___4Oxz = "PlasmicPricingSection3-module--freeBox___4Oxz--rM84c";
export var freeBox__xjQkK = "PlasmicPricingSection3-module--freeBox__xjQkK--1rVYc";
export var freeBox__xleEk = "PlasmicPricingSection3-module--freeBox__xleEk--1JJky";
export var freeBox__mdV4U = "PlasmicPricingSection3-module--freeBox__mdV4U--1nsNX";
export var freeBox__wuo1J = "PlasmicPricingSection3-module--freeBox__wuo1J--1jLrn";
export var freeBox__ywKaV = "PlasmicPricingSection3-module--freeBox__ywKaV--2ZMJ3";
export var freeBox__dgppM = "PlasmicPricingSection3-module--freeBox__dgppM--1KWpx";
export var freeBox__m2Nhe = "PlasmicPricingSection3-module--freeBox__m2Nhe--3FhYb";
export var freeBox___5H7Ru = "PlasmicPricingSection3-module--freeBox___5H7Ru--1HUdw";
export var divider__of16M = "PlasmicPricingSection3-module--divider__of16M--urQOV";
export var freeBox___80LAi = "PlasmicPricingSection3-module--freeBox___80LAi--lfIiO";
export var freeBox__bs9Yf = "PlasmicPricingSection3-module--freeBox__bs9Yf--32R-S";
export var valueprop__gbdoN = "PlasmicPricingSection3-module--valueprop__gbdoN--3ZR0H";
export var svg__gy7Xg = "PlasmicPricingSection3-module--svg__gy7Xg--nAoho";
export var svg__g98KW = "PlasmicPricingSection3-module--svg__g98KW--3fet_";
export var valueprop__n83Kx = "PlasmicPricingSection3-module--valueprop__n83Kx--2jBbx";
export var svg__z1UlT = "PlasmicPricingSection3-module--svg__z1UlT--2okHJ";
export var svg__h6Z2S = "PlasmicPricingSection3-module--svg__h6Z2S--hB9xS";
export var svg___3QF6P = "PlasmicPricingSection3-module--svg___3QF6P--173gM";
export var freeBox___2VF5 = "PlasmicPricingSection3-module--freeBox___2VF5--xwFHw";
export var svg__ce4Qg = "PlasmicPricingSection3-module--svg__ce4Qg--3WJ1v";
export var freeBox__dMzsH = "PlasmicPricingSection3-module--freeBox__dMzsH--2nRnJ";
export var freeBox__smy6E = "PlasmicPricingSection3-module--freeBox__smy6E--2SLhq";
export var freeBox__jsl8A = "PlasmicPricingSection3-module--freeBox__jsl8A--1xTwz";
export var freeBox__gIjCu = "PlasmicPricingSection3-module--freeBox__gIjCu--2V-oN";
export var freeBox__l3GXi = "PlasmicPricingSection3-module--freeBox__l3GXi--Xh0i7";
export var freeBox__xumQy = "PlasmicPricingSection3-module--freeBox__xumQy--ZZdts";
export var freeBox___8K2KF = "PlasmicPricingSection3-module--freeBox___8K2KF--20skF";
export var freeBox___0I7MH = "PlasmicPricingSection3-module--freeBox___0I7MH--2dFqx";
export var divider___5IEoy = "PlasmicPricingSection3-module--divider___5IEoy--2319N";
export var freeBox__s9PqZ = "PlasmicPricingSection3-module--freeBox__s9PqZ--3ShDf";
export var freeBox__hleFp = "PlasmicPricingSection3-module--freeBox__hleFp--3twQl";
export var valueprop__vh9 = "PlasmicPricingSection3-module--valueprop__vh9--k4NaZ";
export var svg__z1Khn = "PlasmicPricingSection3-module--svg__z1Khn--nvSM_";
export var svg__zfqAn = "PlasmicPricingSection3-module--svg__zfqAn--2F0yj";
export var valueprop__d7H5F = "PlasmicPricingSection3-module--valueprop__d7H5F--z0V1j";
export var freeBox___3PmDg = "PlasmicPricingSection3-module--freeBox___3PmDg--2n9Op";
export var svg__e5D6B = "PlasmicPricingSection3-module--svg__e5D6B--2AlF-";
export var svg__efCX = "PlasmicPricingSection3-module--svg__efCX--1dOL5";
export var valueprop__nTfPo = "PlasmicPricingSection3-module--valueprop__nTfPo--3pkKd";
export var svg___4SxnX = "PlasmicPricingSection3-module--svg___4SxnX--3Lecn";
export var svg__zYVwZ = "PlasmicPricingSection3-module--svg__zYVwZ--1gAcF";
export var valueprop___8CpIa = "PlasmicPricingSection3-module--valueprop___8CpIa--2F1pJ";
export var freeBox__qddRs = "PlasmicPricingSection3-module--freeBox__qddRs--QNnpH";
export var svg__mFzVp = "PlasmicPricingSection3-module--svg__mFzVp--2Hy2f";
export var svg__sXlOm = "PlasmicPricingSection3-module--svg__sXlOm--2LXKO";
export var svg__kut4O = "PlasmicPricingSection3-module--svg__kut4O--2a6mr";
export var freeBox__hkGfH = "PlasmicPricingSection3-module--freeBox__hkGfH--1TiKk";
export var svg__aDchv = "PlasmicPricingSection3-module--svg__aDchv--RtuSG";
export var freeBox__sMsH4 = "PlasmicPricingSection3-module--freeBox__sMsH4--3Hg2O";
export var freeBox__nqcZu = "PlasmicPricingSection3-module--freeBox__nqcZu--EHeEO";
export var freeBox__qvrdw = "PlasmicPricingSection3-module--freeBox__qvrdw--25kf4";
export var freeBox__cLuKn = "PlasmicPricingSection3-module--freeBox__cLuKn--1grDo";
export var freeBox__gHcLw = "PlasmicPricingSection3-module--freeBox__gHcLw--3jOi1";
export var freeBox__eLscj = "PlasmicPricingSection3-module--freeBox__eLscj--1Moc6";
export var freeBox__tSw4 = "PlasmicPricingSection3-module--freeBox__tSw4--2JYNq";
export var freeBox__m7OB = "PlasmicPricingSection3-module--freeBox__m7OB--1IGIv";
export var divider___5A1V1 = "PlasmicPricingSection3-module--divider___5A1V1--3Rqzx";
export var freeBox__qzcjj = "PlasmicPricingSection3-module--freeBox__qzcjj--3v73B";
export var freeBox__vYay4 = "PlasmicPricingSection3-module--freeBox__vYay4--3q4Ip";
export var valueprop___7U7K = "PlasmicPricingSection3-module--valueprop___7U7K--3kcZ7";
export var svg__ck1Es = "PlasmicPricingSection3-module--svg__ck1Es--3yuWm";
export var svg__zd3P1 = "PlasmicPricingSection3-module--svg__zd3P1--N3HRR";
export var valueprop__aPmmC = "PlasmicPricingSection3-module--valueprop__aPmmC--3-SR7";
export var svg___8V12X = "PlasmicPricingSection3-module--svg___8V12X--3mwmf";
export var svg__fWdK8 = "PlasmicPricingSection3-module--svg__fWdK8--3TQMb";
export var svg__l46Xy = "PlasmicPricingSection3-module--svg__l46Xy--1qD0F";
export var freeBox__xk0Lm = "PlasmicPricingSection3-module--freeBox__xk0Lm--1id3U";
export var svg__aaCfs = "PlasmicPricingSection3-module--svg__aaCfs--3GZqa";