// extracted by mini-css-extract-plugin
export var root = "PlasmicTestimonial-module--root--WwfNy";
export var freeBox__n1AHa = "PlasmicTestimonial-module--freeBox__n1AHa--hgE03";
export var slotChildren = "PlasmicTestimonial-module--slotChildren--3YNJj";
export var freeBox__zovFl = "PlasmicTestimonial-module--freeBox__zovFl--1UtY0";
export var img__pjNmh = "PlasmicTestimonial-module--img__pjNmh--1yOcm";
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--3RHcf";
export var freeBox__gmOwv = "PlasmicTestimonial-module--freeBox__gmOwv--2N1f2";
export var freeBox__xciHa = "PlasmicTestimonial-module--freeBox__xciHa--cHijb";
export var slotSlot2 = "PlasmicTestimonial-module--slotSlot2--2HGw_";
export var slotSlot3 = "PlasmicTestimonial-module--slotSlot3--3NYND";