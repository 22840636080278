// extracted by mini-css-extract-plugin
export var root = "PlasmicValuesSection3-module--root--1Coli";
export var foreground = "PlasmicValuesSection3-module--foreground--3TXW3";
export var freeBox__ppOXb = "PlasmicValuesSection3-module--freeBox__ppOXb--1EDct";
export var freeBox__rxAsR = "PlasmicValuesSection3-module--freeBox__rxAsR--1_hEg";
export var h2___5TRnY = "PlasmicValuesSection3-module--h2___5TRnY--381i8";
export var freeBox__cFbmb = "PlasmicValuesSection3-module--freeBox__cFbmb--1sg2t";
export var freeBox__aYOzW = "PlasmicValuesSection3-module--freeBox__aYOzW--_eoZX";
export var freeBox__h1Kd3 = "PlasmicValuesSection3-module--freeBox__h1Kd3--3o4vl";
export var valueprop__sQbwn = "PlasmicValuesSection3-module--valueprop__sQbwn--26MsZ";
export var svg___4Dav1 = "PlasmicValuesSection3-module--svg___4Dav1--WLpKl";
export var svg___1CyYc = "PlasmicValuesSection3-module--svg___1CyYc--1xGwG";
export var valueprop__jvjUy = "PlasmicValuesSection3-module--valueprop__jvjUy--Jq8k8";
export var svg__pbEl = "PlasmicValuesSection3-module--svg__pbEl--v9ixy";
export var svg__eNgJq = "PlasmicValuesSection3-module--svg__eNgJq--1WMxA";
export var valueprop__zBEeA = "PlasmicValuesSection3-module--valueprop__zBEeA--3kwkv";
export var svg__tqbQs = "PlasmicValuesSection3-module--svg__tqbQs--3Z89S";
export var svg__z9ST0 = "PlasmicValuesSection3-module--svg__z9ST0--1C1W1";
export var valueprop__jRbsU = "PlasmicValuesSection3-module--valueprop__jRbsU--2ZohI";
export var svg__oIkKk = "PlasmicValuesSection3-module--svg__oIkKk--1dejO";
export var svg__krSUj = "PlasmicValuesSection3-module--svg__krSUj--39e9e";
export var freeBox__aqckp = "PlasmicValuesSection3-module--freeBox__aqckp--10t_J";
export var freeBox__klAjD = "PlasmicValuesSection3-module--freeBox__klAjD--Bistu";
export var freeBox__mQYtO = "PlasmicValuesSection3-module--freeBox__mQYtO--3gIzW";
export var valueprop__nrSg = "PlasmicValuesSection3-module--valueprop__nrSg--U4m9X";
export var svg__yyG2 = "PlasmicValuesSection3-module--svg__yyG2--T4k_V";
export var svg__iP4Qm = "PlasmicValuesSection3-module--svg__iP4Qm--OrzQH";
export var freeBox__qUvwO = "PlasmicValuesSection3-module--freeBox__qUvwO--3EFpn";
export var valueprop__tccDk = "PlasmicValuesSection3-module--valueprop__tccDk--2TvlL";
export var svg__qt5Pk = "PlasmicValuesSection3-module--svg__qt5Pk--1vN9g";
export var svg__tt8KZ = "PlasmicValuesSection3-module--svg__tt8KZ--3r0qY";
export var valueprop___4U9Sg = "PlasmicValuesSection3-module--valueprop___4U9Sg--2PH0g";
export var svg__l9KI2 = "PlasmicValuesSection3-module--svg__l9KI2--2f7la";
export var svg__aygUy = "PlasmicValuesSection3-module--svg__aygUy--1oqGq";
export var valueprop___8NHvI = "PlasmicValuesSection3-module--valueprop___8NHvI--2peYf";
export var svg__cVP = "PlasmicValuesSection3-module--svg__cVP--3taIL";
export var svg___6M7E = "PlasmicValuesSection3-module--svg___6M7E--3wadH";