// extracted by mini-css-extract-plugin
export var root = "PlasmicValuesSection2Dark-module--root--2YOmi";
export var foreground = "PlasmicValuesSection2Dark-module--foreground--248WW";
export var freeBox___6MbR0 = "PlasmicValuesSection2Dark-module--freeBox___6MbR0--TeMuP";
export var freeBox__qv3Ld = "PlasmicValuesSection2Dark-module--freeBox__qv3Ld--bOFxT";
export var h2__tKZcJ = "PlasmicValuesSection2Dark-module--h2__tKZcJ--5WI55";
export var freeBox__pVzFk = "PlasmicValuesSection2Dark-module--freeBox__pVzFk--3wOE-";
export var button___9DehK = "PlasmicValuesSection2Dark-module--button___9DehK--10akV";
export var svg___78Na = "PlasmicValuesSection2Dark-module--svg___78Na--139Yj";
export var freeBox__wsY0W = "PlasmicValuesSection2Dark-module--freeBox__wsY0W--2dqau";
export var svg__yLPsu = "PlasmicValuesSection2Dark-module--svg__yLPsu--2DBmK";
export var freeBox___2HXrZ = "PlasmicValuesSection2Dark-module--freeBox___2HXrZ--2snzT";
export var listItem__jbvSu = "PlasmicValuesSection2Dark-module--listItem__jbvSu--vA84A";
export var freeBox__upd59 = "PlasmicValuesSection2Dark-module--freeBox__upd59--2MK-g";
export var svg__hkB8I = "PlasmicValuesSection2Dark-module--svg__hkB8I--266VI";
export var listItem__q2Hac = "PlasmicValuesSection2Dark-module--listItem__q2Hac--370f9";
export var freeBox__mzLug = "PlasmicValuesSection2Dark-module--freeBox__mzLug--gfgFg";
export var svg__kzIoq = "PlasmicValuesSection2Dark-module--svg__kzIoq--3H8aN";
export var listItem___6URfc = "PlasmicValuesSection2Dark-module--listItem___6URfc--1Wisd";
export var freeBox__kyFa2 = "PlasmicValuesSection2Dark-module--freeBox__kyFa2--2pzQr";
export var svg__xz7Uk = "PlasmicValuesSection2Dark-module--svg__xz7Uk--Xh9a6";
export var listItem__jWkpI = "PlasmicValuesSection2Dark-module--listItem__jWkpI--1E1xU";
export var freeBox__rfBcs = "PlasmicValuesSection2Dark-module--freeBox__rfBcs--1OoQv";
export var svg___8ASg = "PlasmicValuesSection2Dark-module--svg___8ASg--2ipet";
export var listItem___0Wkxo = "PlasmicValuesSection2Dark-module--listItem___0Wkxo--1ETeL";
export var freeBox__tsrfu = "PlasmicValuesSection2Dark-module--freeBox__tsrfu--27fnT";
export var svg__ai26C = "PlasmicValuesSection2Dark-module--svg__ai26C--2SVsW";