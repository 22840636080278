// extracted by mini-css-extract-plugin
export var root = "PlasmicButton-module--root--_729Y";
export var root__startIcon = "PlasmicButton-module--root__startIcon--2peRv";
export var root__colors_green = "PlasmicButton-module--root__colors_green--3l6Ce";
export var root__colors_indigo = "PlasmicButton-module--root__colors_indigo--2yyO4";
export var root__colors_blue = "PlasmicButton-module--root__colors_blue--2TX3D";
export var root__colors_red = "PlasmicButton-module--root__colors_red--15h0C";
export var root__colors_white = "PlasmicButton-module--root__colors_white--1Vd05";
export var root__round = "PlasmicButton-module--root__round--e6k7g";
export var root__flat = "PlasmicButton-module--root__flat--13Z97";
export var root__noGap = "PlasmicButton-module--root__noGap--2Oyod";
export var root__extraSmallShadow = "PlasmicButton-module--root__extraSmallShadow--9PflG";
export var root__noLabel = "PlasmicButton-module--root__noLabel--xT3PN";
export var root__ouline = "PlasmicButton-module--root__ouline--3gqye";
export var root__darkGray = "PlasmicButton-module--root__darkGray--3S00A";
export var root__bgDifference = "PlasmicButton-module--root__bgDifference--3bxfl";
export var root__linkLabel = "PlasmicButton-module--root__linkLabel--3ykMk";
export var svg__ruzO = "PlasmicButton-module--svg__ruzO--39ZD3";
export var slotSlot = "PlasmicButton-module--slotSlot--1Foxb";
export var slotSlot__colors_green = "PlasmicButton-module--slotSlot__colors_green--55HQt";
export var slotSlot__colors_indigo = "PlasmicButton-module--slotSlot__colors_indigo--1G8qR";
export var slotSlot__colors_blue = "PlasmicButton-module--slotSlot__colors_blue--3ci9A";
export var slotSlot__colors_red = "PlasmicButton-module--slotSlot__colors_red--1eiG4";
export var slotSlot__large = "PlasmicButton-module--slotSlot__large--SZjSM";
export var slotSlot__linkLabel = "PlasmicButton-module--slotSlot__linkLabel--1HI11";
export var slotSlot__navLink = "PlasmicButton-module--slotSlot__navLink--3z0DE";
export var slotSlot__darkGray = "PlasmicButton-module--slotSlot__darkGray--OMntm";
export var slotSlot__dark = "PlasmicButton-module--slotSlot__dark--X2wmt";
export var root__navLink = "PlasmicButton-module--root__navLink--28_Of";
export var __wab_slot = "PlasmicButton-module--__wab_slot--1S-d6";
export var svg___8VeCf = "PlasmicButton-module--svg___8VeCf--3sI63";