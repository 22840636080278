// extracted by mini-css-extract-plugin
export var root = "PlasmicShopBanners-module--root--1zAIk";
export var banner__ptCbt = "PlasmicShopBanners-module--banner__ptCbt--3l3gC";
export var img__wuzoq = "PlasmicShopBanners-module--img__wuzoq--KhYnb";
export var __wab_imgSpacer = "PlasmicShopBanners-module--__wab_img-spacer--2Smln";
export var freeBox__kxx9C = "PlasmicShopBanners-module--freeBox__kxx9C--1GvrI";
export var freeBox__hdf2T = "PlasmicShopBanners-module--freeBox__hdf2T--3_qiR";
export var svg__ru4Kf = "PlasmicShopBanners-module--svg__ru4Kf--1Ys1W";
export var freeBox__fNdXx = "PlasmicShopBanners-module--freeBox__fNdXx--13fud";
export var svg__wGu6X = "PlasmicShopBanners-module--svg__wGu6X--2Fqxg";
export var freeBox__uu4GR = "PlasmicShopBanners-module--freeBox__uu4GR--TAHSp";
export var img___93UaM = "PlasmicShopBanners-module--img___93UaM--3TZgT";
export var freeBox__or2S8 = "PlasmicShopBanners-module--freeBox__or2S8--3unal";
export var freeBox__yo1X = "PlasmicShopBanners-module--freeBox__yo1X--3rPQw";
export var svg__sgsK4 = "PlasmicShopBanners-module--svg__sgsK4--2ayW7";
export var freeBox__e5VHs = "PlasmicShopBanners-module--freeBox__e5VHs--LX4-r";
export var svg__ws3Cx = "PlasmicShopBanners-module--svg__ws3Cx--ukE3r";
export var banner___1JsWy = "PlasmicShopBanners-module--banner___1JsWy--2DMwH";
export var img__ig4N = "PlasmicShopBanners-module--img__ig4N--1NfOQ";
export var freeBox__o2Xuz = "PlasmicShopBanners-module--freeBox__o2Xuz--1Bxsx";
export var freeBox___5YYEb = "PlasmicShopBanners-module--freeBox___5YYEb--2RFZB";
export var svg__c89B9 = "PlasmicShopBanners-module--svg__c89B9--23EOG";
export var freeBox__zalpb = "PlasmicShopBanners-module--freeBox__zalpb--280f_";
export var svg__ing7P = "PlasmicShopBanners-module--svg__ing7P--3sN4G";