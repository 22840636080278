// extracted by mini-css-extract-plugin
export var root = "PlasmicTestimonialsSection1-module--root--17Yjd";
export var freeBox = "PlasmicTestimonialsSection1-module--freeBox--3ed_j";
export var testimonial__itfzz = "PlasmicTestimonialsSection1-module--testimonial__itfzz--I1l2Z";
export var img__g2KeY = "PlasmicTestimonialsSection1-module--img__g2KeY--164Vu";
export var testimonial___5WoxM = "PlasmicTestimonialsSection1-module--testimonial___5WoxM--3Ns0T";
export var img__s3DlT = "PlasmicTestimonialsSection1-module--img__s3DlT--tFBcL";
export var __wab_imgSpacer = "PlasmicTestimonialsSection1-module--__wab_img-spacer--31sgt";
export var testimonial__hOVgd = "PlasmicTestimonialsSection1-module--testimonial__hOVgd--2PQUS";
export var freeBox__g49A = "PlasmicTestimonialsSection1-module--freeBox__g49A--3kjHe";
export var freeBox__h7Cgv = "PlasmicTestimonialsSection1-module--freeBox__h7Cgv--3zBjH";
export var img__tQyCf = "PlasmicTestimonialsSection1-module--img__tQyCf--2VLaA";
export var testimonial__hvjnv = "PlasmicTestimonialsSection1-module--testimonial__hvjnv---r9f4";
export var freeBox__amG6I = "PlasmicTestimonialsSection1-module--freeBox__amG6I--2fZgF";
export var img__k8W01 = "PlasmicTestimonialsSection1-module--img__k8W01--3_Pw6";