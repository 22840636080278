// extracted by mini-css-extract-plugin
export var root = "PlasmicValueprop-module--root--3bIDY";
export var root__vertical = "PlasmicValueprop-module--root__vertical--32suH";
export var root__card = "PlasmicValueprop-module--root__card--3abhA";
export var freeBox__xPvct = "PlasmicValueprop-module--freeBox__xPvct--tKDCz";
export var freeBox__flat__xPvctRgTv = "PlasmicValueprop-module--freeBox__flat__xPvctRgTv--Rz9Hp";
export var freeBox__vertical__xPvcttXiPp = "PlasmicValueprop-module--freeBox__vertical__xPvcttXiPp--3FaXF";
export var freeBox__card__xPvctNq2UG = "PlasmicValueprop-module--freeBox__card__xPvctNq2UG--2Y33W";
export var freeBox__flatIcon__xPvctDBY = "PlasmicValueprop-module--freeBox__flatIcon__xPvctDBY--u9OUG";
export var img__vun9F = "PlasmicValueprop-module--img__vun9F--RGP8V";
export var __wab_imgSpacer = "PlasmicValueprop-module--__wab_img-spacer--22Cud";
export var freeBox__zIupA = "PlasmicValueprop-module--freeBox__zIupA--1G84t";
export var freeBox__flat__zIupArgTv = "PlasmicValueprop-module--freeBox__flat__zIupArgTv--2ULMB";
export var freeBox__vertical__zIupAtXiPp = "PlasmicValueprop-module--freeBox__vertical__zIupAtXiPp--3yWFb";
export var freeBox__card__zIupANq2UG = "PlasmicValueprop-module--freeBox__card__zIupANq2UG--36JtX";
export var freeBox__flatIcon__zIupAdBY = "PlasmicValueprop-module--freeBox__flatIcon__zIupAdBY--ceynB";
export var svg__sm1At = "PlasmicValueprop-module--svg__sm1At--2tWie";
export var freeBox__l2OLk = "PlasmicValueprop-module--freeBox__l2OLk--1bld1";
export var slotChildren = "PlasmicValueprop-module--slotChildren--13kOM";
export var slotChildren__dark = "PlasmicValueprop-module--slotChildren__dark--3CV3M";
export var slotSlot = "PlasmicValueprop-module--slotSlot--fxUz5";
export var slotSlot__dark = "PlasmicValueprop-module--slotSlot__dark--3VUG1";